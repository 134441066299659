@font-face {
  font-family: 'Century Gothic Std';
  src: url('./../assets/fonts/CenturyGothicW05-Regular.eot');
  src: local('Nissan Brand Regular'), local('NissanBrand-Regular'),
    url('./../assets/fonts/CenturyGothicW05-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/CenturyGothicW05-Regular.woff2') format('woff2'),
    url('./../assets/fonts/CenturyGothicW05-Regular.woff') format('woff'),
    url('./../assets/fonts/CenturyGothicW05-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body,
button {
  font-family: 'Century Gothic Std', arial, sans-serif;
}

canvas {
  width: 100vw;
  height: 100vh;
  outline: none;
}

.debug {
  position: fixed;
  top: 10px;
  left: 10px;

  button {
    margin-left: 10px;
    opacity: 0.3;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    appearance: none;
    border: 1px solid #999;
    border-radius: 2px;
    padding: 2px 5px;
    user-select: none;

    &.active {
      background-color: #333;
      color: #fff;
    }

    &.secondary {
      opacity: 0.15;
    }
  }
}

.debug-env {
  margin-top: 12px;
}

#fps {
  position: fixed;
  background-color: black;
  text-align: center;
  font-size: 12px;
  color: white;
  top: 10px;
  right: 10px;
  width: 60px;
  padding: 3px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.infos {
  position: fixed;
  bottom: 15px;
  left: 15px;
  font-size: 12px;
}

#babylonjsLoadingDiv img {
  display: none;
}
#babylonjsLoadingDiv::before {
  content: url('../assets/images/loader.gif');
}

.is-blankstate {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-blankstate::before {
  opacity: 1;
  content: url('../assets/images/diorBlankState.png');
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
}
